<template>
  <div>
    <ControlNavbar />
    <page-header :title="mainMenu.label" />
    <div class="container-fluid" v-if="mainMenu && start">
      <div class="banner" v-if="mainMenu.banner">
        <MyBanner :banner="mainMenu.banner" />
      </div>
      <br />
      <br />
      <div
        class="categories"
        v-for="(category, index) in mainMenu.data.items"
        :key="index"
      >
        <div class="category row align-items-center">
          <div
            class="col-3 col-sm-2 col-md-2"
            v-if="category"
            @click="showPrices(category)"
          >
            <p>
              <img
                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                :src="category.iconUrl"
                :alt="'could not load ' + (category.icon || 'image 404')"
                width="60px"
              />
            </p>
            <p>{{ category.label }}</p>
          </div>
          <div class="col-9 col-sm-10 col-md-10">
            <div class="items">
              <slick ref="slick" :options="slickOptions">
                <div v-for="(item, index) in category.data.items" :key="index">
                  <div class="item" v-if="item">
                    <div class="cost" @click="showPrices(category)">
                      <img
                        onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                        :src="item.iconUrl"
                        :alt="item.icon"
                        style="width: 70px"
                      />
                      <p style="font-size: 13px">{{ item.label }}</p>
                    </div>
                  </div>
                </div>
              </slick>
            </div>
          </div>
        </div>
      </div>
      <br /><br /><br />
    </div>
    <a-modal
      v-model="visible"
      v-if="showPricesObject"
      :title="showPricesObject.label"
      @ok="toggleModal"
      :footer="null"
    >
      <div
        class="d-flex justify-content-between"
        v-for="(item, index) in showPricesObject.data.items"
        :key="index"
      >
        <div>{{ item.data.name }}</div>
        <div>{{ item.label }}</div>
      </div>
    </a-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import axios from "axios";
import firebase from "firebase";
import Slick from "vue-slick";
import ControlNavbar from "@/components/ControlNavbar";
import PageHeader from "./PageHeader.vue";

export default {
  name: "Costs",
  components: { Slick, ControlNavbar, PageHeader },
  data() {
    return {
      showPricesObject: null,
      start: false,
      mainMenu: null,
      visible: false,

      slickOptions: {
        rtl: this.$session.get("direction") == "rtl" ? true : false,
        infinite: false,
        arrows: false,
        paddingLeft: "0px",
        paddingRight: "0px",
        marginRight: "0px",
        slidesToShow: 6,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 10,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 7,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 390,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  methods: {
    toggleModal() {
      this.visible = !this.visible;
    },

    showPrices(category) {
      console.log(category);
      this.showPricesObject = category;
      this.toggleModal();
    },
    getAxios() {
      return axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
      });
    },
    next() {
      this.$refs.slick.next();
    },
    prev() {
      this.$refs.slick.prev();
    },
    checkApiResponse(result) {
      if (result.headers.sessioncounter) {
        this.$session.set("sessionCounter", result.headers.sessioncounter);
      }
      if (!result.data.status) {
        // error
        this.$message.error(result.data.dialog.message);
        console.error("server switch error : ", result);
        if (result.data.code === "auth.session_expired") {
          // log user out
          this.logout();
        }
      } else {
        return true;
      }
      return false;
    },
    logout() {
      this.getAxios()
        .post(`${this.$session.get("baseURI")}account/session/deactivate`, {
          sessionId: this.$session.get("sessionId"),
        })
        .then((result) => {
          if (this.checkApiResponse(result)) {
            this.$session.clear();
            firebase
              .auth()
              .signOut()
              .then(() => {
                this.$router.replace("/login").catch(() => {});
                console.log("Sign-out successful");

                // Sign-out successful.
              })
              .catch((error) => {
                console.log(error);
                // An error happened.
              });
          } else {
            this.$session.clear();
            firebase
              .auth()
              .signOut()
              .then(() => {
                this.$router.replace("/login").catch(() => {});
                console.log("Sign-out successful");

                // Sign-out successful.
              })
              .catch((error) => {
                console.log(error);
                // An error happened.
              });
          }
        });
    },

    reInit() {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    },
  },
  created: function() {
    console.log(
      "data: ",
      this.$route.params.data || this.$session.get("backup")
    );
    if (!this.$route.params.data) {
      // undefined
      this.mainMenu = this.$session.get("backup");
      this.start = true;
    } else {
      this.mainMenu = this.$route.params.data;
      this.$session.set("backup", this.$route.params.data);
      this.start = true;
    }
  },
};
</script>

<style lang="scss">
.slick-track {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
</style>

<style lang="scss" scoped>
.categories {
  .category {
    margin-bottom: 10px;
    .category-title {
      background: #e62946;
      color: white;
      border-top-right-radius: 45px;
      border-bottom-right-radius: 45px;
      padding: 1.25% 2% 0px 1%;
      // width: fit-content !important;
      max-width: fit-content;
      // min-width: fit-content;
      font-size: 60%;
    }
    .items {
      border-bottom: 1px solid black;
      a {
        text-decoration: none;
        color: black;
      }
      .item {
        margin-right: 10px !important;
        width: fit-content;
        img {
          padding: 5px;
          margin: auto;
          max-width: 80px;
        }
      }
    }
  }
}
</style>
